import axios, {
  type AxiosError,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios';
import { getNormalisedErrorResponseProperties } from '@/api/getNormalisedAxiosProperties';
import { v4 as uuidv4 } from 'uuid';

import { useAuthStore } from '@/stores/authStore';

const axiosInstance = axios.create({
  withCredentials: true,
  timeout: 150000,
  headers: {
    common: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  },
});

export const correlationInterceptor = (req: InternalAxiosRequestConfig) => {
  const uuid = uuidv4();
  req.headers['x-correlation-id'] = uuid;

  return req;
};

export const authTokenInterceptor = async (req: InternalAxiosRequestConfig) => {
  if (req.isPublic) {
    return req;
  }

  const authStore = useAuthStore();

  await authStore.checkIfTokenIsRefreshing();

  return req;
};

axiosInstance.interceptors.request.use(authTokenInterceptor);
axiosInstance.interceptors.request.use(correlationInterceptor);

const authTokenRefresh = (requestConfig?: InternalAxiosRequestConfig) => {
  const config = requestConfig || ({} as InternalAxiosRequestConfig);

  const authStore = useAuthStore();

  authStore.refreshToken();

  config._retry = true;

  return axiosInstance(requestConfig as InternalAxiosRequestConfig);
};

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const requestConfig = error?.config;

    const { status } = getNormalisedErrorResponseProperties(error.response);

    const isRefreshNeeded = status === 401 && !requestConfig?._retry;

    if (isRefreshNeeded) {
      return authTokenRefresh(requestConfig);
    }

    return Promise.reject(error);
  },
);

const axiosWrapper = {
  get<T>(url: string, config?: AxiosRequestConfig) {
    return axiosInstance.get<T>(url, config);
  },
  post<T>(url: string, data?: any, config?: AxiosRequestConfig) {
    return axiosInstance.post<T>(url, data, config);
  },
  put: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosInstance.put(url, data, config),
  patch: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosInstance.patch(url, data, config),
  delete: (url: string, config?: AxiosRequestConfig) =>
    axiosInstance.delete(url, config),
  request: (config: AxiosRequestConfig) => axiosInstance(config),
  isCancel: (error: any) => axios.isCancel(error),
  CancelToken: axios.CancelToken,
  interceptors: axiosInstance.interceptors,
  defaults: axiosInstance.defaults,
};

export default axiosWrapper;
