import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const axiosInstance = axios.create({
  withCredentials: true,
  timeout: 150000,
  headers: {
    common: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  },
});

axiosInstance.interceptors.request.use(async (req) => {
  return req;
});

axiosInstance.interceptors.request.use((req) => {
  const uuid = uuidv4();
  req.headers['x-correlation-id'] = uuid;

  return req;
});

axiosInstance.interceptors.response.use(
  (res) => res.data,
  (error) => {
    return Promise.reject(error);
  },
);

export const authHttpService = axiosInstance;
